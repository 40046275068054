import React from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { Box, Container, CssBaseline, Link, Typography } from '@mui/material';

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Container maxWidth='sm'>
            <Typography variant='body2' color='text.secondary' align='center' {...props}>
                {'Powered by '}
                <Link color='inherit' href='https://vegacloud.io/'>
                    Vega
                </Link>
            </Typography>
        </Container>
    );
};

const useStyles = makeStyles<IFooterProps>()((theme, props) => ({}));

export { Footer };
