/* Options:
Date: 2022-09-02 13:32:51
Version: 6.2
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum SubjectType
{
    Support = 'Support',
    Password = 'Password',
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class BaseQuicksightCache
{
    public cacheId: number;

    public constructor(init?: Partial<BaseQuicksightCache>) { (Object as any).assign(this, init); }
}

export class QuicksightUser extends BaseQuicksightCache
{
    public active: boolean;
    public arn: string;
    public customPermissionsName: string;
    public email: string;
    public externalLoginFederationProviderType: string;
    public externalLoginFederationProviderUrl: string;
    public externalLoginId: string;
    public identityType: string;
    public principalId: string;
    public role: string;
    public userName: string;

    public constructor(init?: Partial<QuicksightUser>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightGroup extends BaseQuicksightCache
{
    public arn: string;
    public description: string;
    public groupName: string;
    public principalId: string;

    public constructor(init?: Partial<QuicksightGroup>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightDashboardSummary extends BaseQuicksightCache
{
    public arn: string;
    public createdTime: string;
    public dashboardId: string;
    public lastPublishedTime: string;
    public lastUpdatedTime: string;
    public name: string;
    public publishedVersionNumber: number;

    public constructor(init?: Partial<QuicksightDashboardSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightDataSetSummary extends BaseQuicksightCache
{
    public arn: string;
    public columnLevelPermissionRulesApplied: boolean;
    public createdTime: string;
    public dataSetId: string;
    public importMode: string;
    public lastUpdatedTime: string;
    public name: string;
    public rowLevelPermissionDataSet: string;
    public rowLevelPermissionTagConfigurationApplied: boolean;

    public constructor(init?: Partial<QuicksightDataSetSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightTemplateSummary extends BaseQuicksightCache
{
    public arn: string;
    public createdTime: string;
    public lastUpdatedTime: string;
    public latestVersionNumber: number;
    public name: string;
    public templateId: string;

    public constructor(init?: Partial<QuicksightTemplateSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightPermissions
{
    public actions: string[];
    public principal: string;

    public constructor(init?: Partial<QuicksightPermissions>) { (Object as any).assign(this, init); }
}

export class QuicksightEmbeddedUrl
{
    public url: string;

    public constructor(init?: Partial<QuicksightEmbeddedUrl>) { (Object as any).assign(this, init); }
}

export class ServerStats
{
    public redis: { [index: string]: number; };
    public serverEvents: { [index: string]: string; };
    public mqDescription: string;
    public mqWorkers: { [index: string]: number; };

    public constructor(init?: Partial<ServerStats>) { (Object as any).assign(this, init); }
}

export class RequestLogEntry
{
    public id: number;
    public traceId: string;
    public operationName: string;
    public dateTime: string;
    public statusCode: number;
    public statusDescription: string;
    public httpMethod: string;
    public absoluteUri: string;
    public pathInfo: string;
    // @StringLength(2147483647)
    public requestBody: string;

    public requestDto: Object;
    public userAuthId: string;
    public sessionId: string;
    public ipAddress: string;
    public forwardedFor: string;
    public referer: string;
    public headers: { [index: string]: string; };
    public formData: { [index: string]: string; };
    public items: { [index: string]: string; };
    public responseHeaders: { [index: string]: string; };
    public session: Object;
    public responseDto: Object;
    public errorResponse: Object;
    public exceptionSource: string;
    public exceptionData: any;
    public requestDuration: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RequestLogEntry>) { (Object as any).assign(this, init); }
}

export class DiagnosticEntry
{
    public id: number;
    public traceId: string;
    public source: string;
    public eventType: string;
    public message: string;
    public operation: string;
    public threadId: number;
    public error: ResponseStatus;
    public commandType: string;
    public command: string;
    public userAuthId: string;
    public sessionId: string;
    public arg: string;
    public args: string[];
    public argLengths: number[];
    public namedArgs: { [index: string]: Object; };
    public duration?: string;
    public timestamp: number;
    public date: string;
    public tag: string;
    public stackTrace: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<DiagnosticEntry>) { (Object as any).assign(this, init); }
}

export class GetVersionResponse
{
    public results: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVersionResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightUsersResponse
{
    public results: QuicksightUser[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightUsersResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightGroupsResponse
{
    public results: QuicksightGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightGroupsResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightDashboardResponse
{
    public results: QuicksightDashboardSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightDashboardResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightDataSetResponse
{
    public results: QuicksightDataSetSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightDataSetResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightUserGroupsResponse
{
    public results: QuicksightGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightUserGroupsResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightTemplatesResponse
{
    public results: QuicksightTemplateSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetQuicksightDashboardPermissionsResponse
{
    public results: QuicksightPermissions[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuicksightDashboardPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class GetQuicksightDashboardEmbeddedUrlResponse
{
    public results: QuicksightEmbeddedUrl;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuicksightDashboardEmbeddedUrlResponse>) { (Object as any).assign(this, init); }
}

export class DeleteQuicksightUserResponse
{
    public status: boolean;

    public constructor(init?: Partial<DeleteQuicksightUserResponse>) { (Object as any).assign(this, init); }
}

export class StartQuicksightCacheUpdateResponse
{
    public results: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StartQuicksightCacheUpdateResponse>) { (Object as any).assign(this, init); }
}

export class AdminDashboardResponse
{
    public serverStats: ServerStats;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdminDashboardResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RequestLogsResponse
{
    // @DataMember(Order=1)
    public results: RequestLogEntry[];

    // @DataMember(Order=2)
    public usage: { [index: string]: string; };

    // @DataMember(Order=3)
    public total: number;

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RequestLogsResponse>) { (Object as any).assign(this, init); }
}

export class AdminProfilingResponse
{
    public results: DiagnosticEntry[];
    public total: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdminProfilingResponse>) { (Object as any).assign(this, init); }
}

// @Route("/version")
export class GetVersion implements IReturn<GetVersionResponse>
{

    public constructor(init?: Partial<GetVersion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetVersion'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetVersionResponse(); }
}

/**
* ContactSupport
*/
// @Route("/support", "POST")
// @Api(Description="ContactSupport")
export class ContactSupport implements IReturnVoid, IPost
{
    /**
    * User's First Name
    */
    // @ApiMember(DataType="string", Description="User's First Name", IsRequired=true, Name="FirstName")
    public firstName: string;

    /**
    * User's Last Name
    */
    // @ApiMember(DataType="string", Description="User's Last Name", IsRequired=true, Name="LastName")
    public lastName: string;

    /**
    * User's Email Address
    */
    // @ApiMember(DataType="string", Description="User's Email Address", IsRequired=true, Name="Email")
    public email: string;

    /**
    * Type of Subject
    */
    // @ApiMember(DataType="enum", Description="Type of Subject", IsRequired=true, Name="SubjectType")
    public subjectType: SubjectType;

    /**
    * User's Feedback
    */
    // @ApiMember(DataType="string", Description="User's Feedback", IsRequired=true, Name="Feedback")
    public feedback: string;

    /**
    * Subject
    */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Subject")
    public subject: string;

    public constructor(init?: Partial<ContactSupport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContactSupport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/quicksight/users")
export class ListQuicksightUsers implements IReturn<ListQuicksightUsersResponse>
{

    public constructor(init?: Partial<ListQuicksightUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightUsersResponse(); }
}

// @Route("/quicksight/groups")
export class ListQuicksightGroups implements IReturn<ListQuicksightGroupsResponse>
{

    public constructor(init?: Partial<ListQuicksightGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightGroupsResponse(); }
}

// @Route("/quicksight/dashboards")
export class ListQuicksightDashboards implements IReturn<ListQuicksightDashboardResponse>
{

    public constructor(init?: Partial<ListQuicksightDashboards>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightDashboards'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightDashboardResponse(); }
}

// @Route("/quicksight/datasets")
export class ListQuicksightDataSet implements IReturn<ListQuicksightDataSetResponse>
{

    public constructor(init?: Partial<ListQuicksightDataSet>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightDataSet'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightDataSetResponse(); }
}

// @Route("/quicksight/users/{name}/groups")
export class ListQuicksightUserGroups implements IReturn<ListQuicksightUserGroupsResponse>
{
    public name: string;

    public constructor(init?: Partial<ListQuicksightUserGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightUserGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightUserGroupsResponse(); }
}

// @Route("/quicksight/templates")
export class ListQuicksightTemplates implements IReturn<ListQuicksightTemplatesResponse>
{

    public constructor(init?: Partial<ListQuicksightTemplates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightTemplatesResponse(); }
}

// @Route("/quicksight/dashboard/{name}/permissions")
export class GetQuicksightDashboardPermissions implements IReturn<GetQuicksightDashboardPermissionsResponse>
{
    public name: string;

    public constructor(init?: Partial<GetQuicksightDashboardPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQuicksightDashboardPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetQuicksightDashboardPermissionsResponse(); }
}

// @Route("/quicksight/dashboard/url/{name}/")
export class GetQuicksightDashboardEmbeddedUrl implements IReturn<GetQuicksightDashboardEmbeddedUrlResponse>
{
    public name: string;

    public constructor(init?: Partial<GetQuicksightDashboardEmbeddedUrl>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQuicksightDashboardEmbeddedUrl'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetQuicksightDashboardEmbeddedUrlResponse(); }
}

// @Route("/quicksight/deleteUser")
export class DeleteQuicksightUser implements IReturn<DeleteQuicksightUserResponse>
{
    public userName: string;
    public groupName: string;

    public constructor(init?: Partial<DeleteQuicksightUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteQuicksightUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeleteQuicksightUserResponse(); }
}

// @Route("/quicksight/cache/update/start")
export class StartQuicksightCacheUpdate implements IReturn<StartQuicksightCacheUpdateResponse>
{

    public constructor(init?: Partial<StartQuicksightCacheUpdate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartQuicksightCacheUpdate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StartQuicksightCacheUpdateResponse(); }
}

export class AdminDashboard implements IReturn<AdminDashboardResponse>
{

    public constructor(init?: Partial<AdminDashboard>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminDashboard'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdminDashboardResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/requestlogs")
// @DataContract
export class RequestLogs implements IReturn<RequestLogsResponse>
{
    // @DataMember(Order=1)
    public beforeSecs?: number;

    // @DataMember(Order=2)
    public afterSecs?: number;

    // @DataMember(Order=3)
    public operationName: string;

    // @DataMember(Order=4)
    public ipAddress: string;

    // @DataMember(Order=5)
    public forwardedFor: string;

    // @DataMember(Order=6)
    public userAuthId: string;

    // @DataMember(Order=7)
    public sessionId: string;

    // @DataMember(Order=8)
    public referer: string;

    // @DataMember(Order=9)
    public pathInfo: string;

    // @DataMember(Order=10)
    public ids: number[];

    // @DataMember(Order=11)
    public beforeId?: number;

    // @DataMember(Order=12)
    public afterId?: number;

    // @DataMember(Order=13)
    public hasResponse?: boolean;

    // @DataMember(Order=14)
    public withErrors?: boolean;

    // @DataMember(Order=15)
    public enableSessionTracking?: boolean;

    // @DataMember(Order=16)
    public enableResponseTracking?: boolean;

    // @DataMember(Order=17)
    public enableErrorTracking?: boolean;

    // @DataMember(Order=18)
    public durationLongerThan?: string;

    // @DataMember(Order=19)
    public durationLessThan?: string;

    // @DataMember(Order=20)
    public skip: number;

    // @DataMember(Order=21)
    public take?: number;

    // @DataMember(Order=22)
    public orderBy: string;

    public constructor(init?: Partial<RequestLogs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RequestLogs'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RequestLogsResponse(); }
}

export class AdminProfiling implements IReturn<AdminProfilingResponse>
{
    public source: string;
    public eventType: string;
    public threadId?: number;
    public traceId: string;
    public userAuthId: string;
    public sessionId: string;
    public tag: string;
    public skip: number;
    public take?: number;
    public orderBy: string;
    public withErrors?: boolean;
    public pending?: boolean;

    public constructor(init?: Partial<AdminProfiling>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminProfiling'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdminProfilingResponse(); }
}

