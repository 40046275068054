import { AppBarProps, Button, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, useTheme } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, ServiceStackClient } from '../../Recoil/Atoms';
import { useState } from 'react';
import { AccountCircle, ArrowDropDownOutlined, ExitToAppOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '../../Styling/MakeStyles';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Authenticate } from '../../ServiceStack/Example.dtos';
import { ReactComponent as VegaLogo } from '../../Images/v-logo.svg';

interface IHeaderProps extends AppBarProps {
    open?: boolean;
    drawerwidth: number;
    toggledrawer: React.MouseEventHandler<HTMLButtonElement>;
}

type Excluded = Omit<IHeaderProps, 'toggledrawer'>;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<Excluded>(({ theme, open, drawerwidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerwidth,
        width: `calc(100% - ${drawerwidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header: React.FC<IHeaderProps> = (props) => {
    const client = useRecoilValue(ServiceStackClient);
    const [authorized, setAuthorized] = useRecoilState(Authentication);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { classes, cx } = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();

    const onLogoutClicked = () => {
        client
            .post(new Authenticate({ provider: 'logout' }))
            .then((response) => {
                setAuthorized(null);
                navigate('/login');
            })
            .catch((error) => console.log(error));
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getMenuItems = (): JSX.Element[] => {
        let menuItems = [];

        menuItems.push(
            <MenuItem key={menuItems.length} onClick={onLogoutClicked}>
                <ListItemIcon>
                    <ExitToAppOutlined className={cx(classes.MenuIcon)} />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </MenuItem>
        );

        return menuItems;
    };

    const { toggledrawer, ...others } = props;
    return (
        <>
            <AppBar
                sx={{ backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]) }}
                position={'absolute'}
                {...others}
                elevation={0}
                color={'transparent'}
            >
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={props.toggledrawer}
                        sx={{
                            marginRight: '36px',
                            ...(props.open && { display: 'none' }),
                        }}
                    >
                        <VegaLogo className={cx(classes.VegaLogo)} />
                    </IconButton>
                    <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
                        Dashboard
                    </Typography>
                    {authorized && (
                        <Button color={'inherit'} variant='text' aria-controls='profile-dropdown' aria-haspopup='true' onClick={onClickProfile}>
                            <AccountCircle className={cx(classes.AccountIcon)} />
                            {authorized.userName}
                            <ArrowDropDownOutlined className={cx(classes.DropdownIcon)} />
                        </Button>
                    )}
                    <Menu
                        id='profile-dropdown'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleCloseMenu}
                    >
                        {getMenuItems()}
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    AppBar: {
        marginBottom: '14rem',
    },
    AccountIcon: {
        marginRight: '.5rem',
    },
    DropdownIcon: {
        marginLeft: '.5rem',
    },
    MenuIcon: {
        fill: theme.palette.common.black,
    },
    VegaLogo: {
        height: '2rem',
        width: '2rem',
    },
    AppBarSeperator: theme.mixins.toolbar as any,
}));

export default Header;
