import * as React from 'react';
import {PropsWithChildren, Suspense, useState} from 'react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {CircularProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {DashboardOutlined, HelpOutlineOutlined} from '@mui/icons-material';
import Header from '../Header/Header';
import {makeStyles} from '../../Styling/MakeStyles';
import {cx} from 'tss-react/@emotion/css';
import {ReactComponent as VegaLogo} from '../../Images/vega-logo.svg';
import {SupportForm} from '../SupportForm/SupportForm';
import {Footer} from '../Footer/Footer';
import {useNavigate} from 'react-router-dom';
import {RouteUrls} from '../../Routes/RouteUrls';
import {CustomSnackbar} from '../Common/CustomSnackbar';
import {useRecoilState} from 'recoil';
import {Authentication} from '../../Recoil/Atoms';
import {SubjectType} from "../../ServiceStack/Example.dtos";

const drawerwidth: number = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerwidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

export interface IDashboardProps extends PropsWithChildren {}

export const Dashboard: React.FC<IDashboardProps> = (props) => {
    const [open, setOpen] = React.useState(true);
    const styles = useStyles({ open });
    const [showSupportForm, setShowSupportForm] = useState(false);
    const navigate = useNavigate();
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);

    const toggledrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CustomSnackbar />
            <SupportForm subjectType={SubjectType.Support} show={showSupportForm} onClose={() => setShowSupportForm(false)} />
            <CssBaseline />
            <Header open={open} drawerwidth={drawerwidth} toggledrawer={toggledrawer} />
            <Drawer elevation={0} variant='permanent' open={open}>
                <Toolbar
                    className={cx(styles.classes.VegaToolbar)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: [1],
                    }}
                >
                    {open && (
                        <>
                            <VegaLogo className={cx(styles.classes.VegaLogo)} />
                            <IconButton onClick={toggledrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </>
                    )}
                </Toolbar>
                <Divider />
                <List component='nav'>
                    <ListItemButton onClick={() => navigate(RouteUrls.Landing)}>
                        <ListItemIcon>
                            <DashboardOutlined />
                        </ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    {authenticationResponse &&
                        authenticationResponse.roles &&
                        authenticationResponse.roles.filter((role) => role === 'Admin').length > 0 && (
                            <ListItemButton onClick={() => navigate(RouteUrls.Admin)}>
                                <ListItemIcon>
                                    <DashboardOutlined />
                                </ListItemIcon>
                                <ListItemText primary='Admin' />
                            </ListItemButton>
                        )}
                </List>
                <List className={cx(styles.classes.Help)}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setShowSupportForm(true)}>
                            <ListItemIcon>
                                <HelpOutlineOutlined />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component='main'
                sx={{
                    backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                    <Suspense
                        fallback={
                            <Grid container sx={{ mt: '10rem' }} direction={'column'} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Loading...</Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid direction={'column'} justifyContent={'space-between'} container>
                            <Grid className={cx(styles.classes.ContentContainer)} item xl={12}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </Suspense>
                </Container>
                <div className={cx(styles.classes.FooterContainer)}>
                    <Footer />
                </div>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
    ContentContainer: {
        marginTop: '4rem',
    },
    FooterContainer: {
        bottom: '0%',
        left: open ? 99 : 0,
        position: 'absolute',
        width: '100%',
    },
    Help: {
        marginTop: `auto`,
    },
    VegaToolbar: {},
    VegaLogo: {
        height: '4rem',
    },
}));
