import React, { useEffect, useState } from 'react';
import { embedDashboard, EmbeddingOptions } from 'amazon-quicksight-embedding-sdk';
import { cx } from 'tss-react/@emotion/css';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '../../Styling/MakeStyles';
import Paper from '@mui/material/Paper';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, GetDashboardUrl, SnackBarOptionsAtom } from '../../Recoil/Atoms';

export interface IQuickSightReportProps {}

export const QuicksightReport: React.FC<IQuickSightReportProps> = (props) => {
    const [quicksightDashboard, setQuicksightDashboard] = useState(null);
    const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);
    const authorized = useRecoilValue(Authentication);
    const dashboardUrl = useRecoilValue(GetDashboardUrl(authorized ? authorized.userName : ''));
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptionsAtom);

    const styles = useStyles();

    const onDashboardLoad = (payload) => {
        setIsDashboardLoaded(true);
    };

    const onError = (payload) => {
        console.log('Do something when the dashboard fails loading');
    };

    useEffect(() => {
        console.log('useEffects - dashboardUrl:' + JSON.stringify(dashboardUrl));
        if (dashboardUrl !== undefined) {
            console.log(dashboardUrl);
            if (dashboardUrl === 'Error') {
                console.log('here');
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: 'There was a problem loading visuals',
                });
            } else {
                loadQuicksightDashboard(dashboardUrl);
            }
        }
    }, [dashboardUrl]);

    const loadQuicksightDashboard = (dUrl) => {
        var options: EmbeddingOptions = {
            url: dUrl,
            scrolling: 'no',
            height: '100%',
            width: '100%',
            locale: 'en-US',
            footerPaddingEnabled: true,
            container: document.getElementById('qsContainer'),
            printEnabled: true,
        };
        const qsDash = embedDashboard({ ...options });
        qsDash.on('error', onError);
        qsDash.on('load', onDashboardLoad);
        setQuicksightDashboard(qsDash);
    };

    return <Card elevation={0} className={`${cx(styles.classes.reportContainer)}`} id='qsContainer' />;
};

const useStyles = makeStyles()(() => ({
    reportContainer: {
        height: '100vh',
        quicksightEmbeddingIframe: {
            quicksightEmbeddingIframe_clickableQuicksightAttribution: {
                display: 'none',
            },
        },
    },
}));
