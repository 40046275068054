import React, { useEffect, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from './RouteUrls';
import { RoutePrivate } from './RoutePrivate';
import { SignIn } from '../Components/SignIn/SignIn';
import { Dashboard } from '../Components/Dashboard/Dashboard';
import { QuicksightReport } from '../Components/QuicksightReport/QuicksightReport';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, ServiceStackClient } from '../Recoil/Atoms';
import { LocationState } from '../Types/Types';
import { Authenticate, GetQuicksightDashboardEmbeddedUrl } from '../ServiceStack/Example.dtos';
import { SignInSplash } from '../Components/SignIn/SignInSplash';
import { Card } from '@mui/material';
import Paper from '@mui/material/Paper';

export interface IVegaCostNavigatorRoutesProps {}

const VegaCostNavigatorRoutes: React.FC<IVegaCostNavigatorRoutesProps> = (props) => {
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);
    const client = useRecoilValue(ServiceStackClient);
    const location = useLocation();
    const navigate = useNavigate();
    //const [dashboardUrl, setDashboardUrl] = useRecoilState(DashboardUrlResponse);

    //ToDo is there a better way to check auth status?  MPG
    useEffect(() => {
        if (location.pathname !== RouteUrls.Login && !authenticationResponse) checkAuthStatus().then((r) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //getEmbeddedDashboardUrl();
    }, []);

    const checkAuthStatus = async () => {
        client
            .post(new Authenticate({ rememberMe: true }))
            .then((response) => {
                client.useTokenCookie = true;
                setAuthenticationResponse(response);
            })
            .catch((error) => console.log(error));
    };

    return (
        <Routes>
            <Route path={RouteUrls.Logout} element={<div>ToDo</div>} />
            <Route path={RouteUrls.Login} element={<SignInSplash />} />
            <Route path={'/'} element={<Navigate to={RouteUrls.Login} />} />
            <Route
                path={'*'}
                element={
                    <RoutePrivate>
                        <Dashboard>
                            <Routes>
                                <Route
                                    path={RouteUrls.Landing}
                                    element={
                                        <RoutePrivate>
                                            <QuicksightReport />
                                        </RoutePrivate>
                                    }
                                />
                                <Route
                                    path={RouteUrls.Admin}
                                    element={
                                        <RoutePrivate>
                                            <Card elevation={0} style={{ height: '100vh', marginBottom: '2rem' }}>
                                                <iframe style={{ height: '100%', width: '100%' }} src='/admin-ui'></iframe>
                                            </Card>
                                        </RoutePrivate>
                                    }
                                />
                            </Routes>
                        </Dashboard>
                    </RoutePrivate>
                }
            />
        </Routes>
    );
};

export { VegaCostNavigatorRoutes };
