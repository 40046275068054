import createPalette from '@mui/material/styles/createPalette';

export const VegaPalette = createPalette({
    common: {
        black: '#000',
        white: '#FFF',
    },
    primary: {
        light: '#00A1E0',
        main: '#1E75BB',
        dark: '#00668F',
        contrastText: '#FFF',
    },
    secondary: {
        light: '#DA6C7B',
        main: '#D1495B',
        dark: '#C43145',
        contrastText: '#FFF',
    },
    success: {
        light: '#06DB9B',
        main: '#04A777',
        dark: '#048B63',
        contrastText: '#23330A',
    },
    warning: {
        light: '#FBCF23',
        main: '#EDAE49',
        dark: '#EBA433',
        contrastText: '#6B5500',
    },
    grey: {
        50: '#F3F4F6',
        100: '#E8E9ED',
        200: '#D2D5DD',
        300: '#D0D4DC',
        400: '#C5C9D3',
        500: '#B9BECA',
        600: '#AEB3C2',
        700: '#A2A8B9',
        800: '#969DB0',
        900: '#8B92A7',
        A100: '#7F879F',
        A200: '#737D96',
        A400: '#69728C',
        A700: '#606980',
    },
    text: {
        primary: '#02213B',
        secondary: '#69728C',
        disabled: '#D2D5DD',
    },
    divider: '#C5C9D3',
    action: {
        active: 'rgba(96, 105, 128, 0.54)',
        hover: 'rgba(96, 105, 128, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(96, 105, 128, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(96, 105, 128, 0.26)',
        disabledBackground: 'rgba(96, 105, 128, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(96, 105, 128, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
});
